// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2h08ix_774UyQEqkOzpeTF {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  width: -moz-available;\n  width: -webkit-fill-available;\n  width: fill-available;\n}\n\n.Jfx6Zj8A5Bo602lmJA_RV {\n  font-weight: normal;\n  white-space: nowrap;\n}\n\n.gZ9ZMzMF9ZQNWpWWDhSnP {\n  margin-left: 0.25rem;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n", ""]);
// Exports
exports.locals = {
	"labelContainer": "_2h08ix_774UyQEqkOzpeTF",
	"caption": "Jfx6Zj8A5Bo602lmJA_RV",
	"varietiesRoot": "gZ9ZMzMF9ZQNWpWWDhSnP"
};
module.exports = exports;
