// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AKwPTtAx5qwaUyST0460U {\n  padding-left: 4px;\n  margin-top: -2px;\n  font-size: 0.875rem;\n}\n\n._38CTF22msbg6fJLzzkmQ2d {\n  margin-bottom: 0 !important;\n  height: 2rem;\n}\n\n._1_tFjYbUVZYQrHeKBHTZwV {\n  height: 4rem;\n}\n\n._3Pk3xSYDfeqUQYkn0esGJJ {\n  align-self: center;\n}\n\n._1VuxtL0jQbLeszF0kKtly9 {\n  font-size: 0.75rem !important;\n}\n\n._2fvKutz1TZrZNc4jKq7N8t {\n  margin-bottom: -1.5rem;\n  margin-left: -1.5rem;\n  margin-right: -1.5rem;\n  overflow: auto;\n}\n\n._2s-M5VINFKCo98h1peHC-1 {\n  display: flex;\n  overflow: auto;\n  flex-direction: column;\n}\n\n._2hHJGjfcgGv15hhzI7Xth1 {\n  font-size: 0.875rem;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n._1FzlM8vUiAN8dt-FxnIXY4 {\n  padding: 0.625rem;\n}\n\n.umOPBjQMHVyy339HAtRnv {\n  margin-bottom: 0.75rem;\n}\n\n.em_UkVQX1-ehiQVvWVxnE {\n  margin-left: -1.25em;\n  margin-right: -1.25em;\n  height: 100%;\n  background-color: transparent !important;\n}\n\n.em_UkVQX1-ehiQVvWVxnE:hover > svg {\n  stroke: black;\n}\n\n._2eBcR1v9Zne-wJ9zd6wJYt {\n  height: 100%;\n}\n\n", ""]);
// Exports
exports.locals = {
	"tagText": "AKwPTtAx5qwaUyST0460U",
	"addButton": "_38CTF22msbg6fJLzzkmQ2d",
	"productColumns": "_1_tFjYbUVZYQrHeKBHTZwV",
	"productColumn": "_3Pk3xSYDfeqUQYkn0esGJJ",
	"expandedTextCategory": "_1VuxtL0jQbLeszF0kKtly9",
	"productsSection": "_2fvKutz1TZrZNc4jKq7N8t",
	"productsCardContent": "_2s-M5VINFKCo98h1peHC-1",
	"detailProductName": "_2hHJGjfcgGv15hhzI7Xth1",
	"detailProductColumn": "_1FzlM8vUiAN8dt-FxnIXY4",
	"textHeader": "umOPBjQMHVyy339HAtRnv",
	"editButton": "em_UkVQX1-ehiQVvWVxnE",
	"modalContainer": "_2eBcR1v9Zne-wJ9zd6wJYt"
};
module.exports = exports;
