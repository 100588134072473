// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zXE9JNpOZDl1hZ1Vfl7uH {\n  font-size: 1.125rem;\n  color: rgba(0, 0, 0, 0.87);\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.zXE9JNpOZDl1hZ1Vfl7uH:hover {\n  cursor: pointer;\n  color: rgba(0, 0, 0, 0.66);\n}\n\n._3N-W9oNJmBWkkxs1U5-g-C {\n  display: grid;\n  grid-auto-flow: column;\n  align-items: center;\n}\n\n._2CVqzfDIr92x8KnK2ev918 {\n  padding: 0.875rem 1rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"dropdownTitle": "zXE9JNpOZDl1hZ1Vfl7uH",
	"dropdownBox": "_3N-W9oNJmBWkkxs1U5-g-C",
	"selectCropTypeLabel": "_2CVqzfDIr92x8KnK2ev918"
};
module.exports = exports;
