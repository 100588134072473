import React, { ReactElement, useState } from 'react';

import ControlledSwitch, { TSwitchControlledProps } from './Controlled';

type TSwitchUncontrolledProps = {
	className?: string;
	disabled?: boolean;
	label?: string;
} & TSwitchControlledProps;

const Uncontrolled = (props: TSwitchUncontrolledProps): ReactElement => {
	const { className, disabled, label } = props;
	const [checked, setChecked] = useState(false);

	return (
		<ControlledSwitch
			className={className}
			checked={checked}
			checkedChangeHandler={setChecked}
			disabled={disabled}
			label={label}
		/>
	);
};

export default Uncontrolled;
