// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2wKH_2bMKS1RbXFvmCo-Mt > div {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n  z-index: 999;\n}\n\n.aam1gGAaTsWWI6k-Vrtwy {\n  position: relative;\n}\n\n", ""]);
// Exports
exports.locals = {
	"consentManagerWrapper": "_2wKH_2bMKS1RbXFvmCo-Mt",
	"consentManagerContainer": "aam1gGAaTsWWI6k-Vrtwy"
};
module.exports = exports;
