// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3U0gB9nUx4immE3z27OtI0 {\n  display: flex;\n  align-items: center;\n  margin-top: 0;\n  height: 4rem;\n  justify-content: space-between;\n}\n\n.jzn1j1LqnY9BrHStUSeL_ {\n  padding: 1.25rem 1rem 1.25rem 1.5rem;\n}\n\n.IDZgwiluv-7hBhgOEDjGM {\n  margin: 0;\n}\n\n._1DWHIEswb3trpzm4eDswTA {\n  font-size: 1.325rem;\n}\n\n._2xRe9Z505WrM1LQT4PPQCz {\n  padding: 0;\n}\n\n._2xRe9Z505WrM1LQT4PPQCz:last-child {\n  padding-bottom: 0;\n}\n\n._18FDhK34Qyf7BCojStwVL {\n  padding: 0 1.5rem;\n}\n\n._18FDhK34Qyf7BCojStwVL:hover {\n  background-color: rgba(0, 0, 0, 0.04);\n  cursor: pointer;\n}\n\n", ""]);
// Exports
exports.locals = {
	"treatment": "_3U0gB9nUx4immE3z27OtI0",
	"treatmentsTitleSection": "jzn1j1LqnY9BrHStUSeL_",
	"treatmentsAction": "IDZgwiluv-7hBhgOEDjGM",
	"treatmentsTitle": "_1DWHIEswb3trpzm4eDswTA",
	"treatmentsContent": "_2xRe9Z505WrM1LQT4PPQCz",
	"treatmentItem": "_18FDhK34Qyf7BCojStwVL"
};
module.exports = exports;
