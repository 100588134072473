// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2vESEdxYjaisM-d_SpSiA7 {\n  max-width: 42.5rem;\n  margin: 2rem auto;\n}\n\n._22xe9vkgmWALK_x3vrK-xe {\n  padding: 0 !important;\n}\n\n.LknwItq4NAkRPfkPRsEOb {\n  padding: 1rem 1rem 1rem 1.5rem;\n  align-items: center;\n}\n\n._1f5f5jxD-mep1FvQ9ir4h9 {\n  align-items: center;\n  padding-left: 1.5rem;\n  margin-top: 0;\n  border-top: 1px solid #e9e8f1;\n  min-height: 4rem;\n}\n\n._1f5f5jxD-mep1FvQ9ir4h9 > p {\n  line-height: normal;\n}\n\n._1f5f5jxD-mep1FvQ9ir4h9:hover {\n  background-color: rgba(0, 0, 0, 0.04);\n  cursor: pointer;\n}\n\n._3qh-lBfA_hS6byLf01Fq1d {\n  align-items: center;\n  padding-left: 1.5rem;\n  margin-top: 0;\n  border-top: 1px solid #e9e8f1;\n  min-height: 4rem;\n  background-color: rgba(0, 0, 0, 0.04);\n}\n\n._2BcTIy2iACMQ4eMhyCS2-n {\n  background-color: rgba(0, 0, 0, 0.04);\n  padding: 0 1.5rem 1.5rem 1.5rem;\n}\n\n._2YuYqx3V3h9EaAqdHwYoV6 {\n  padding: 0.5rem 1.5rem 1.5rem 1.5rem;\n  background-color: #fff;\n}\n\n.piPqFgonIQ_qQTWgZ5mRB {\n  padding: 1rem 0;\n  border-bottom: 1px solid #e9e8f1;\n}\n\n.piPqFgonIQ_qQTWgZ5mRB > p {\n  line-height: normal;\n}\n\n.uw9qjZ7VbY1zDxFI5lhWJ {\n  padding-top: 0.75rem;\n}\n\n._2up6yzG4i2TfGu4vkAsmTz {\n  font-size: 16px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"libraryCard": "_2vESEdxYjaisM-d_SpSiA7",
	"libraryCardContent": "_22xe9vkgmWALK_x3vrK-xe",
	"libraryCardHeader": "LknwItq4NAkRPfkPRsEOb",
	"product": "_1f5f5jxD-mep1FvQ9ir4h9",
	"selectedProduct": "_3qh-lBfA_hS6byLf01Fq1d",
	"productDetailsWrapper": "_2BcTIy2iACMQ4eMhyCS2-n",
	"productDetails": "_2YuYqx3V3h9EaAqdHwYoV6",
	"productTreatmentItem": "piPqFgonIQ_qQTWgZ5mRB",
	"addTreatmentButton": "uw9qjZ7VbY1zDxFI5lhWJ",
	"productSelected": "_2up6yzG4i2TfGu4vkAsmTz"
};
module.exports = exports;
