// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../public/assets/img/fields-splash-low-res.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".PH7fgkQCep4t9dHzGSQfU {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  height: 100vh;\n}\n\n.Gw_BlT1cvGhpeo-hW2J8f {\n  background-color: #fff;\n  max-width: 33rem;\n  height: 100%;\n}\n\n._1xMAPHG0y6q6-8rCjlQIIq {\n  width: 23rem;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n._2_n25OP5H_PBrCtQgv3bNY {\n  padding: 1rem 0;\n  width: 100%;\n  margin: auto;\n  max-height: 100%;\n  overflow: auto;\n}\n\n@media (max-width: 26rem) {\n  ._1xMAPHG0y6q6-8rCjlQIIq {\n    margin: auto;\n    width: 100%;\n  }\n\n  ._2_n25OP5H_PBrCtQgv3bNY {\n    padding: 1rem 1.5rem;\n  }\n}\n\n._3d7P69W8cTycQ6S5kKXcgl {\n  display: block;\n  margin: 0 auto;\n  margin-bottom: 1.5rem;\n}\n\n._1e34V_8ACbmxDV50Kmelg3 {\n  margin-bottom: 3rem !important;\n  text-align: center;\n}\n\n.zx4spA984RGlRv_J2zGDl {\n  padding: 0.25rem 0;\n  font-weight: bold;\n  flex-grow: 2;\n}\n\n.L-Kr-ZbO3k4Pjj56i5uJZ {\n  position: relative;\n}\n\n._1A-atgOP1ayXDeiHouIVe {\n  position: absolute;\n  left: 1rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"splashBackground": "PH7fgkQCep4t9dHzGSQfU",
	"loginBar": "Gw_BlT1cvGhpeo-hW2J8f",
	"content": "_1xMAPHG0y6q6-8rCjlQIIq",
	"scrollContainer": "_2_n25OP5H_PBrCtQgv3bNY",
	"logo": "_3d7P69W8cTycQ6S5kKXcgl",
	"descriptionText": "_1e34V_8ACbmxDV50Kmelg3",
	"loginButtonDescription": "zx4spA984RGlRv_J2zGDl",
	"button": "L-Kr-ZbO3k4Pjj56i5uJZ",
	"buttonIcon": "_1A-atgOP1ayXDeiHouIVe"
};
module.exports = exports;
