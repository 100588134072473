// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2nM_bpUIDYVGzglRmQLGL1 {\n  margin-bottom: 0.25rem;\n}\n\n._35yP3yJF5pm9g1J2tI1UPb {\n  padding: 1.5rem !important;\n}\n\n._3q95ncAywFO71yYNyx4kcR {\n  width: 28.125rem;\n}\n\n._3m5jcEfyLvYCHuydWr4S8P {\n  position: absolute !important;\n  left: 1rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"textHeader": "_2nM_bpUIDYVGzglRmQLGL1",
	"cardContent": "_35yP3yJF5pm9g1J2tI1UPb",
	"modalContent": "_3q95ncAywFO71yYNyx4kcR",
	"backButton": "_3m5jcEfyLvYCHuydWr4S8P"
};
module.exports = exports;
