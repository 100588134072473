// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3qaGDvsKpdrWpY4-CCapNJ {\n  padding: 4px 8px;\n  border-radius: 4px;\n  background-color: rgba(216, 62, 20, 0.12);\n  color: #d83e14;\n  font-size: 0.875rem;\n}\n\n._5zlyNR6lL3uP1vRI5prel {\n  background-color: #fafafa;\n  border-radius: 0.2rem;\n  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16), 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 2px 2px 0 rgba(0, 0, 0, 0.12);\n  padding: 0.75rem;\n}\n\n._29KqjjBSyB1pbHXaXF_l-g:last-child {\n  margin-bottom: 0;\n}\n\n._29KqjjBSyB1pbHXaXF_l-g {\n  margin-bottom: 1rem;\n}\n\n._2nGBWFF08XHNDL8_tHSh79 {\n  font-size: 0.875rem;\n  margin-bottom: 0.5rem;\n}\n\n.rpHk8Le9peXzGrGPCkt5- {\n  margin-bottom: 0.5rem;\n}\n\n.rpHk8Le9peXzGrGPCkt5-:last-child {\n  margin-bottom: 0;\n}\n\n._2Vs2zTz5EiQ2spaLM1ptw6 {\n  color: rgba(0, 0, 0, 0.6);\n  font-size: 0.75rem;\n  margin-bottom: 0.1rem;\n}\n\n._3nYhn9ntHbUfEM3-rZ_7gj {\n  display: flex;\n  justify-content: space-between;\n  column-gap: 1rem;\n  font-size: 0.875rem;\n}\n\n._3G0b0qz2rn9-cemXAq1tOI {\n  font-size: 1rem;\n}\n\n._3kwl1TAa1dDVOOrJJZIzeV {\n  font-size: 0.875rem;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n._1XFxXfi9gChSeO6vEhB-9a {\n  font-size: 1rem;\n}\n\n._3TXTt1MrLEhmcQLcWaKpA2 {\n  display: flex;\n  margin-right: 0.125rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"subtitle": "_3qaGDvsKpdrWpY4-CCapNJ",
	"tooltipContainer": "_5zlyNR6lL3uP1vRI5prel",
	"tooltipCurrencySection": "_29KqjjBSyB1pbHXaXF_l-g",
	"tooltipCurrencyHeader": "_2nGBWFF08XHNDL8_tHSh79",
	"tooltipSection": "rpHk8Le9peXzGrGPCkt5-",
	"tooltipHeader": "_2Vs2zTz5EiQ2spaLM1ptw6",
	"tooltipSeriesItem": "_3nYhn9ntHbUfEM3-rZ_7gj",
	"legendMarker": "_3G0b0qz2rn9-cemXAq1tOI",
	"legendSeriesName": "_3kwl1TAa1dDVOOrJJZIzeV",
	"legendValue": "_1XFxXfi9gChSeO6vEhB-9a",
	"tooltipBase": "_3TXTt1MrLEhmcQLcWaKpA2"
};
module.exports = exports;
