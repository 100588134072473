// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1gMjQHFOG0TVUBsG09_Fww {\n  overflow: unset;\n  padding-bottom: 0.5rem !important;\n}\n\n", ""]);
// Exports
exports.locals = {
	"subtitle": "_1gMjQHFOG0TVUBsG09_Fww"
};
module.exports = exports;
