import _ from 'lodash';

import partnerPortalDe from '../translations/partner-portal/de.json';
import partnerPortalEn from '../translations/partner-portal/en.json';
import partnerPortalEs from '../translations/partner-portal/es.json';
import partnerPortalNb from '../translations/partner-portal/nb.json';
import webPortalDe from '../translations/web-portal/de.json';
import webPortalEn from '../translations/web-portal/en.json';
import webPortalEs from '../translations/web-portal/es.json';
import webPortalNb from '../translations/web-portal/nb.json';

export const resources = {
	en: {
		translation: _.defaultsDeep({ ...partnerPortalEn }, webPortalEn),
	},
	de: {
		translation: _.defaultsDeep({ ...partnerPortalDe }, webPortalDe),
	},
	es: {
		translation: _.defaultsDeep({ ...partnerPortalEs }, webPortalEs),
	},
	nb: {
		translation: _.defaultsDeep({ ...partnerPortalNb }, webPortalNb),
	},
};
