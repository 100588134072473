import React, { ReactElement, useState } from 'react';

import ControlledCheckbox from './Controlled';

type TCheckboxUncontrolledProps = {
	className?: string;
	disabled?: boolean;
	label?: string;
};

const Uncontrolled = (props: TCheckboxUncontrolledProps): ReactElement => {
	const { className, disabled, label } = props;
	const [checked, setChecked] = useState(false);

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.currentTarget.checked);
	};

	return (
		<ControlledCheckbox
			className={className}
			checked={checked}
			onChange={(e) => changeHandler(e)}
			disabled={disabled}
			label={label}
		/>
	);
};

export default Uncontrolled;
