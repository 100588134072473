import React from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import Permissions from 'Models/permissions/Permissions';
import Divider from 'UI/display/Divider';
import SpinnerModal from 'UI/display/modal/spinner';
import AddIcon from 'UI/icons/Add';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import CardIcon from 'UI/icons/Card';
import SettingsIcon from 'UI/icons/Settings';
import TeamIcon from 'UI/icons/Team';
import TimeIcon from 'UI/icons/Time';
import UsersIcon from 'UI/icons/Users';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu/menu';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import usePermission from 'Hooks/usePermission';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';
import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';

import CREATE_CUSTOMER_PORTAL from '../../../features/stripe/api/createCustomerPortal';
import DropdownPageItem from './DropdownPageItem';
import styles from './DropdownPageItem.module.scss';
import SettingsMenuHeader from './SettingsMenuHeader';

type TLocationLinkProps = {
	locationId: string;
	label: string;
	onClick?: () => void;
};

const PaymentsNavbar = (props: TLocationLinkProps) => {
	const { locationId } = props;
	const [loadCustomerPortal, loadCustomerPortalTask] = useMutation(
		CREATE_CUSTOMER_PORTAL
	);

	const redirectToStripe = () => {
		void loadCustomerPortal({
			variables: {
				locationId,
				input: { returnUrl: window.location.href },
			},
		}).then((res) => {
			if (res.data) {
				window.location.href = res.data.createCustomerPortalSession;
			}
		});
	};

	return (
		<>
			<DropdownPageItem
				onClick={redirectToStripe}
				icon={CardIcon}
				label={props.label}
			/>
			<SpinnerModal open={loadCustomerPortalTask.loading} />
		</>
	);
};

const LocationSettingsDropdown = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const locationId = getLocationIdFromUrl();
	const { t } = useTranslation();
	const location = useCurrentLocation();
	const isSIEX = location && location.type === 'SIEX';

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const hasPermissionsToSeeDropdown = usePermission(
		Permissions.NAVBAR_SETTINGS_DROPDOWN
	);
	const hasStripeRedirectPermission = usePermission(
		Permissions.STRIPE_REDIRECT
	);
	const hasFarmSettingsPermission = usePermission(
		Permissions.FARM_SETTINGS_VIEW
	);
	const hasTimesheetsViewPermission = usePermission(
		Permissions.TIMESHEETS_VIEW
	);
	const hasFieldsImportPermission = usePermission(
		Permissions.FIELD_IMPORTS_PAGE_ACCESS
	);
	const hasSalesAccessPermission = usePermission(
		Permissions.FARM_SALES_PAGE_ACCESS
	);
	const hasValidSalesSubscription = useSubscriptionsFeatureFlag(
		SubscriptionType.SALE
	);

	const fieldImportsSection =
		hasFieldsImportPermission && locationId && !isSIEX
			? [
					<Divider key="import-divider" sx={{ marginY: '0.5rem' }} />,
					<DropdownPageItem
						key="import-link"
						link={`/locations/${locationId}/settings/import/fields`}
						icon={AddIcon}
						label={t(`importPages.fieldImportsPage.header`)}
						onClick={handleClose}
					/>,
			  ]
			: [];

	const paymentSection = hasStripeRedirectPermission
		? [
				<PaymentsNavbar
					locationId={locationId}
					label={t(`common.sidebar.subscriptionsAndPayments`)}
				/>,
		  ]
		: [];

	const settingsSection =
		hasFarmSettingsPermission && locationId
			? [
					<Divider key="farm-settings-divider" sx={{ marginY: '0.5rem' }} />,
					<DropdownPageItem
						key="settings-link"
						label={t(`common.sidebar.accountSettings`)}
						link={`/locations/${locationId}/settings/info`}
						icon={SettingsIcon}
						onClick={handleClose}
					/>,
					<DropdownPageItem
						key="members-link"
						label={t(`common.sidebar.manageMembers`)}
						link={`/locations/${locationId}/settings/members`}
						icon={UsersIcon}
						onClick={handleClose}
					/>,
					paymentSection,
			  ]
			: [];

	const timesheetsSection = hasTimesheetsViewPermission
		? [
				<Divider key="time-divider" sx={{ marginY: '0.5rem' }} />,

				<DropdownPageItem
					key="teams-link"
					label={t(`common.sidebar.teams`)}
					link={`/locations/${locationId}/settings/teams`}
					icon={TeamIcon}
					onClick={handleClose}
				/>,
				<DropdownPageItem
					key="time-settings-link"
					label={t(`common.sidebar.timeSettings`)}
					link={`/locations/${locationId}/settings/time-settings`}
					icon={TimeIcon}
					onClick={handleClose}
				/>,
		  ]
		: [];

	const salesSection =
		hasSalesAccessPermission && hasValidSalesSubscription
			? [
					<Divider key="sales-divider" sx={{ marginY: '0.5rem' }} />,
					<DropdownPageItem
						key="customers-link"
						label={t(`common.sidebar.customers`)}
						link={`/locations/${locationId}/settings/customers`}
						onClick={handleClose}
						icon={UsersIcon}
					/>,
			  ]
			: [];

	const shouldShowTheDropdown = hasPermissionsToSeeDropdown && location;

	const name = location?.name
		? location.name
		: t('common.navbar.farmNameFallback');

	return shouldShowTheDropdown ? (
		<>
			<Box className={styles.farmNameBox} onClick={handleClick}>
				<p className={styles.farmName}>{name}</p>
				<ArrowDropDownIcon />
			</Box>
			<Menu
				id="navbar-farm-name-menu"
				anchorEl={anchorEl}
				transitionDuration={0}
				sx={{ left: '1.5rem', top: '-0.25rem' }}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'navbar-farm-name-menu',
					dense: true,
				}}
			>
				<SettingsMenuHeader name={name} />
				{settingsSection}
				{timesheetsSection}
				{salesSection}
				{fieldImportsSection}
			</Menu>
		</>
	) : null;
};

export default LocationSettingsDropdown;
