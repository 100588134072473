// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".QHDNMzBUOof246xoRwN-h {\n  padding: 0.25rem 0;\n}\n\n._3V8djuQU44vvqv_s6WmuAc {\n  margin: 0.5rem 0;\n}\n\n._1uljg4HnbQOwc43KLECDqg {\n  position: absolute;\n  left: 1rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"loginButtonDescription": "QHDNMzBUOof246xoRwN-h",
	"buttonLink": "_3V8djuQU44vvqv_s6WmuAc",
	"buttonIcon": "_1uljg4HnbQOwc43KLECDqg"
};
module.exports = exports;
