export const treatmentLibraryProductSpecFragmentFields = `
registrationNumber
fracNumber
reEntryInterval
treatmentTime
note
manufacturerName
traderRegistrationNumber
fertilizerMaterial {
  id
  name
}
organicCarbon
licenseExpirationDate
usageExpirationDate
governmentRemarks
ghsSignalWord
`;
