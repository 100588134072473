// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3BO-CGxXMFrHEZEWVDhL4C {\n  padding-left: 0.75rem;\n  display: flex;\n  justify-content: center;\n}\n\n._3U052RCR9FHKTj1pclP-S7 {\n  padding-left: 1rem;\n}\n\n._13kx3v8acXb6EVoXkdL44k {\n  font-size: 1rem;\n  font-weight: bold;\n  color: rgba(0, 0, 0, 0.87);\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n._2BBK92r2TVd8JiMGePh4SZ {\n  display: grid;\n  grid-auto-flow: column;\n  align-items: center;\n  padding: 1rem 1.5rem;\n}\n\n._2BBK92r2TVd8JiMGePh4SZ:hover {\n  color: rgba(0, 0, 0, 0.6);\n  background-color: rgba(0, 0, 0, 0.03);\n  cursor: pointer;\n}\n\n", ""]);
// Exports
exports.locals = {
	"iconLayout": "_3BO-CGxXMFrHEZEWVDhL4C",
	"dropdownItemStyle": "_3U052RCR9FHKTj1pclP-S7",
	"farmName": "_13kx3v8acXb6EVoXkdL44k",
	"farmNameBox": "_2BBK92r2TVd8JiMGePh4SZ"
};
module.exports = exports;
