// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2yJ4S9VRwl1eBBJS-GU90Q {\n  overflow: auto;\n  position: absolute;\n  top: 9.6875rem;\n  bottom: 0.5rem;\n  width: 100%;\n}\n\n._1ld98tc6N7oebnPK4WBnnJ {\n  display: flex;\n  flex-direction: row;\n  padding: 0.625rem 1.5rem;\n  border-width: 1px 0 1px 0;\n  border-color: #e0e0e0;\n  border-style: solid;\n}\n\n", ""]);
// Exports
exports.locals = {
	"farmsList": "_2yJ4S9VRwl1eBBJS-GU90Q",
	"tableHeaders": "_1ld98tc6N7oebnPK4WBnnJ"
};
module.exports = exports;
