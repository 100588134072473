export const ingredientsFragmentFields = `
id
name
amount
unit
active
organicAmount
organicUnit
registrationNumber
groupName
`;
