import React, { ReactElement, useState } from 'react';

import Controlled, {
	TDateRange,
	TControlledDateRangePickerProps,
} from './Controlled';

type TUncontrolledDateRangePickerProps = Omit<
	TControlledDateRangePickerProps,
	'toggleOpen' | 'open' | 'value'
>;

function Uncontrolled(props: TUncontrolledDateRangePickerProps): ReactElement {
	const [range, setRange] = useState<TDateRange | null>(null);
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen((previousState) => !previousState);

	return (
		<Controlled
			value={range}
			toggleOpen={toggleOpen}
			open={open}
			onChangeValue={setRange}
			{...props}
		/>
	);
}

export default Uncontrolled;
