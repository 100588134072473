// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1eFRlVmSBnmjZaunUMsiV1 {\n  overflow: auto;\n  position: absolute;\n  top: 4.125rem;\n  bottom: 0.5rem;\n  width: 100%;\n  padding: 0 1rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"eventsList": "_1eFRlVmSBnmjZaunUMsiV1"
};
module.exports = exports;
