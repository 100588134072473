// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3BYafjbzuy3KPhRM23bjUq {\n  margin-bottom: -1.5rem;\n  margin-left: -1.5rem;\n  margin-right: -1.5rem;\n  overflow: auto;\n}\n\n._3BYafjbzuy3KPhRM23bjUq:last-child {\n  border-bottom: 0;\n}\n\n._1dU_6ykxJzZkRzZMvhUP1v {\n  align-items: center;\n  padding-left: 1.5rem;\n  margin-top: 0;\n  border-top: 1px solid #e9e8f1;\n  height: 4rem;\n}\n\n._1dU_6ykxJzZkRzZMvhUP1v:hover {\n  background-color: rgba(0, 0, 0, 0.04);\n  cursor: pointer;\n}\n\n", ""]);
// Exports
exports.locals = {
	"causesSection": "_3BYafjbzuy3KPhRM23bjUq",
	"cause": "_1dU_6ykxJzZkRzZMvhUP1v"
};
module.exports = exports;
