// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._35Ud6RidGQjkjO8w4gAzrD {\n  height: 6.625rem;\n  width: 6.625rem;\n  border-radius: 0.25rem;\n}\n\n._1hxpJaL1hKjDrtTwGuWX5s {\n  display: flex;\n  width: 100%;\n  overflow: auto;\n}\n\n.d4mdY3GHF2bH0ktCExMuv {\n  position: relative;\n  min-width: 6.625rem;\n  min-height: 6.625rem;\n  margin-right: 0.5rem;\n}\n\n._2WwEEGIrWSkVzfPC5_b1bF {\n  position: absolute;\n  width: 1.25rem;\n  height: 1.25rem;\n  top: 0.5rem;\n  right: 1rem;\n  background: white;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n._2WwEEGIrWSkVzfPC5_b1bF:hover {\n  background: rgba(0, 0, 0, 0.12);\n}\n\n", ""]);
// Exports
exports.locals = {
	"previewImage": "_35Ud6RidGQjkjO8w4gAzrD",
	"previewGallery": "_1hxpJaL1hKjDrtTwGuWX5s",
	"previewContainer": "d4mdY3GHF2bH0ktCExMuv",
	"deletePreview": "_2WwEEGIrWSkVzfPC5_b1bF"
};
module.exports = exports;
